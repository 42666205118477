import * as React from "react"
import { graphql } from "gatsby"

import {
  Seo,
  RichTextTickBullets,
  Layout,
  HeaderImage,
  Button,
  ButtonList,
  SectionTextWithImage,
  Accordions,
} from "../components"
import { handleSectionBackground } from "../utils"
import { GenericPageWithHeaderImage } from "../types"

const IndexPage = ({ data, location }: GenericPageWithHeaderImage) => {
  const page = data.contentfulPageGeneric
  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderImage
        image={page.headerImage.gatsbyImageData}
        imageAlt={page.headerImage.title}
        heading={page.heading}
      >
        <RichTextTickBullets variant="header-image" content={page.header} />
        <ButtonList>
          <Button
            style="primary"
            url={`${process.env.GATSBY_PATIENT_PORTAL}/sign-up`}
          >
            Sign up free
          </Button>
          <Button style="secondary" url={process.env.GATSBY_PATIENT_PORTAL}>
            Login
          </Button>
        </ButtonList>
      </HeaderImage>
      {page?.pageSections?.map((section: any, index: number) => {
        if (section.contentful_id !== "6e8smAV8qAE5o74HWfPm42") {
          if (section.__typename === "ContentfulPageSectionTextWithImage") {
            return (
              <SectionTextWithImage
                key={section.contentful_id}
                contentful_id={section.contentful_id}
                backgroundColour={handleSectionBackground(
                  section.backgroundColour,
                  index % 2 === 0 ? "grey-lightest" : "white"
                )}
                heading={section.heading}
                content={section.content}
                image={section?.media?.media?.gatsbyImageData}
                imageAlt={section?.media?.media?.title}
                showImageFirst={section.showImageFirst}
                imageBlobBackground={section.imageBlobBackground}
                showSignUpButton={section.showSignUpButton}
                scheduleDemoButton={section.scheduleDemoButton}
                file={section?.media?.media?.file?.url}
                youTubeVideo={
                  section?.media?.youTubeEmbedCode?.youTubeEmbedCode
                }
              />
            )
          } else if (section.__typename === "ContentfulPageSectionAccordions") {
            return (
              <SectionTextWithImage
                key={section.contentful_id}
                contentful_id={section.contentful_id}
                backgroundColour={handleSectionBackground(
                  section.backgroundColour,
                  index % 2 === 0 ? "grey-lightest" : "white"
                )}
                heading={section.heading}
                content={section.content}
                image={section?.image?.gatsbyImageData}
                imageAlt={section?.image?.title}
                showImageFirst={section.showImageFirst}
                imageBlobBackground={section.imageBlobBackground}
                showSignUpButton={section.showSignUpButton}
                scheduleDemoButton={section.scheduleDemoButton}
                file={section?.image?.file?.url}
                youTubeVideo={
                  section?.media?.youTubeEmbedCode?.youTubeEmbedCode
                }
                variant="accordions"
              >
                <Accordions accordions={section.accordions} />
              </SectionTextWithImage>
            )
          }
        }
      })}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query getLandingPageData {
    contentfulPageGeneric(name: { eq: "Landing page" }) {
      contentful_id
      heading
      header {
        raw
      }
      headerImage {
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      seo {
        description {
          description
        }
        titleTemplate {
          extension
        }
        title
        image {
          fixed {
            src
          }
        }
      }
      pageSections {
        ... on ContentfulPageSectionAccordions {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          accordions {
            heading
            body {
              raw
            }
            contentful_id
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
        ... on ContentfulPageSectionTextWithImage {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
      }
    }
  }
`
